<template>
  <div class="Topstory-container">
    <div class="Topstory-mainColumn">
      <el-tabs v-model="activeName" @tab-click="changeItem">
        <el-tab-pane
          :label="sitem.SectionName"
          :name="sitem.SectionName"
          v-for="(sitem, sindex) in sectionList"
          :key="sindex"
          :sectionId="sitem.Id"
        >
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="postQueryAllTopicList"
            v-if="feedList.length > 0"
          >
            <div v-for="(item, index) in feedList" :key="index">
              <feed-item :feedList="item" :isDelete="false"></feed-item>
            </div>
          </van-list>
          <div v-if="feedList.length <= 0">
            <no-data></no-data>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="关注" name="attention">
          <div v-for="(item,index) in followList" :key="index">
            <feed-item :feedList="item"></feed-item>
          </div>
        </el-tab-pane> -->
      </el-tabs>
      <el-button class="H5AskButton" @click="showAskModal">
        <i class="el-icon-edit"></i>
      </el-button>
      <!-- <div class="Loading" v-show="isLoad">拼命加载中</div>
      <div class="Loading" v-show="isEnd">我是有底线的</div> -->
    </div>
  </div>
</template>
<script>
import { queryAllTopicList, querySectionList, queryStudentInfoById } from "@/api/bbs";
import FeedItem from "@/components/bbs/FeedItem.vue";
import GlobalSideBar from "@/components/bbs/GlobalSideBar.vue";
import Modal from "@/components/bbs/Modal.vue";
import noData from "@/components/noData";
// import { mapGetters } from "vuex";

let Load;
export default {
  name: "index",
  components: {
    FeedItem,
    GlobalSideBar,
    Modal,
    noData
  },
  computed: {
    //监听词条
    // ...mapGetters(["isAdd", "searchList"])
  },
  provide () {
    return {
      finishDelete: ""
    }
  },
  watch: {
    isAdd: function (newValue) {
      // this.getContent();
      this.postQueryAllTopicList();
    },
    searchList: function (newValue) {
      this.feedList = newValue;
      console.log("更新列表");
    },
  },
  data () {
    return {
      feedList: [],
      activeName: "",
      followList: [],
      isLoad: false,
      recPage: 1, //推荐页面page
      // attPage: 1, //关注页面page
      pageSize: 10,
      loading: false,
      finished: false,
      isEnd: false,
      user: {},
      sectionList: [],
      sectionId: 1,
      showLoginModal: false,
      showModal: false,
      disabled: true,
      askDetail: "", //提问细节
      askTip: "",
      askTitle: "", //提问问题
      loginUser: "", //登录用户
      loginPass: "", //注册密码
      sectionName: ''
    };
  },
  created () {
    var that = this;
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.getQueryStudentInfoById();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted () {
    // this.getContent();
    this.user.Id = this.$route.query.UserId > 0 ? this.$route.query.UserId : 0;
    this.sectionName = this.$route.query.SectionName == undefined ? "" : this.$route.query.SectionName;
    if (localStorage.getItem("userInfo") != null && localStorage.getItem("userInfo") != undefined) {
      this.user = JSON.parse(localStorage.getItem("userInfo"));
    } else if (this.user.Id > 0) {
      this.getQueryStudentInfoById();
    } else {
      this.user.Id = 0;
    }
    this.postQuerySectionList();
    // this.postQueryAllTopicList();
    // window.addEventListener( 'scroll', this.listenScroll);
    window.userIDResultByIos = this.userIDResultByIos;
  },
  methods: {
    userIDResultByIos (jsonStr) {
      if (jsonStr != "") {
        this.user.Id = jsonStr.data;
        this.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    },
    callApp () {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(JSON.stringify({ action: "callIosGetUserID" }));
      }
    },
    showLoginModalFn () {
      this.showLoginModal = true;
    },
    showAskModal () {
      if (this.user.Id == 0) {
        this.callApp();
        return false;
      }
      // let routeUrl = this.$router.resolve({
      //   path: "/h5/bbs/edit",
      //   query: {},
      // });
      // window.open(routeUrl.href, "_blank");
      this.$router.push({
        path: "/h5/bbs/edit",
        query: { sectionList: JSON.stringify(this.sectionList), userId: this.user.Id },
      });
    },
    finishadd () {
      this.feedList = [];
      this.recPage = 1;
      this.postQueryAllTopicList();
    },
    //根据Id获取学生信息
    async getQueryStudentInfoById () {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      console.log(this.user);
      localStorage.setItem("userInfo", JSON.stringify(this.user));
    },
    //获取论坛版块
    async postQuerySectionList () {
      // 点赞/取消点赞
      let parm = {};
      parm.PageSize = 10;
      parm.PageIndex = 1;
      parm.Status = 1;
      const res = await querySectionList(parm);
      this.sectionList = res.data;
      if (this.sectionName == '') {
        this.activeName = this.sectionList[0].SectionName;
      } else {
        this.sectionList.forEach(element => {
          if (element.SectionName.indexOf(this.sectionName) > -1) {
            this.activeName = element.SectionName;
            this.sectionId = element.Id;
            return
          }
        });
      }
      this.postQueryAllTopicList();
    },
    // 列表数据
    async postQueryAllTopicList () {
      let parm = {};
      parm.pageIndex = this.recPage;
      parm.pageSize = this.pageSize;
      parm.IsAll = true;
      parm.UserId = this.user.Id;
      parm.sectionId = this.sectionId;
      const res = await queryAllTopicList(parm);
      this.feedList = this.feedList.concat(res.data);
      this.$store.dispatch("setIsAdd", false);
      this.recPage++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.feedList.length >= res.dataCount) {
        this.finished = true;
      }
      // this.isLoad = false;
      // if (res.data.length == 0) {
      //   this.isEnd = true;
      // }
    },
    //滚动
    listenScroll () {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.recPage++;
          that.postQueryAllTopicList();
        }
      }
    },
    changeItem: function (tab, event) {
      this.sectionId = tab.$attrs.sectionId;
      this.feedList = [];
      this.recPage = 1;
      this.postQueryAllTopicList();
    },
    //发表问题
    publishQues () {
      let params = {
        title: this.askTitle,
        content: this.askDetail,
      };
      this.axios.post("/question/add", params).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: "提问成功",
            type: "success",
          });
          this.showModal = false;
          this.askTitle = "";
          this.askDetail = "";
          this.$store.dispatch("setIsAdd", true);
        }
      });
    },
    //监听标题文字数量
    listenChange () {
      if (this.askTitle.length < 4) {
        this.askTip = "至少输入4个字";
        this.disabled = true;
      } else {
        this.askTip = "";
        this.disabled = false;
      }
    },
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>
<style lang="scss" >
@import "../../../assets/css/config";
.Topstory-container {
  display: flex;
  width: 100%;
  // padding: 0 16px;
  // margin: 10px auto;
  margin-top: 0;
}
.Topstory-mainColumn {
  position: relative;
  width: 100%;
  height: 100%;
  .el-tabs__header {
    background: #ffffff;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid #ebebeb;
  }
  .el-tabs__item {
    font-size: 16px;
  }
  .el-tabs__item.is-active {
    color: $mainColor;
  }
  .el-tabs__nav-wrap::after {
    background: transparent;
  }
  .el-tabs__active-bar {
    height: 0;
  }
}
.GlobalSideBar {
  margin-left: 10px;
  flex: 1;
}
.Loading {
  height: 50px;
  text-align: center;
  color: $mainColor;
}
.H5AskButton {
  color: #fff;
  background-color: #0084ff;
  font-size: 20px;
  &:disabled {
    opacity: 0.5;
  }
  position: fixed;
  bottom: 90px;
  right: 2.6%;
  border-radius: 50%;
  width: 44px;
  padding: 12px 0px;
}
.TopstoryItem img {
  max-width: 100%;

  height: 100px;
}
.CommentReply-button {
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 20px;
}
</style>